import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const ShipDelight = () => {
  const headingbanner = {
    title: `Ship Delight`,
    content: `An E-Commerce Logistic Website`,
  };

  const data = {
    images: ["ship-delight-web-2.webp", "ship-delight-web.webp"],
    firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
    firstTitle: "Real Time Shipment Status",
    secondAlt: "UI Dashboard To Check The Performance Status",
    secondTitle: "NDR Status",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Logistics"],
      },
      {
        title: "What we did",
        text: [
          "Branding",
          "UI UX Designing",
          "Wordpress Development",
        ],
      },
      {
        title: "Platform",
        text: ["Website", "Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The company wanted to upgrade their websites and focus on the right positioning to stay ahead in the competitive market.`,
          `They wanted to improve the user experience by offering logistical insights visually and interactively and leveraging the latest tools and technologies for better user understanding.`,
          `Being a fulfilment logistical operations company they needed to optimise operations, enhance security and cater to evolving customer expectations for a seamless and efficient post-purchase journey.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We focused on enhancing the overall performance of the website and started with understanding the user funnel and their requirements to enhance the website performance.`,
          `We aimed to redesign the website interface for improved usability and optimise it for a smooth process.`,
          `We positioned them differently by integrating intuitive navigation, search functionalities and implementing advanced analytics and tracking systems to gather actionable insights for continuous improvements.`
        ],
      },
    ],
    image: ["ship-delight-problem.webp"],
  };

  const projectData = {
    title: "Non-Delivery Report",
    para: [
      `The non-delivery report dashboard shows failed delivery attempts on orders and its detailed report with regards to transit status, reattempt data or any other reasons. We used charts and tables to show the data for better representation and understanding.`,
    ],
    content: {
      image: ["ship-delight-projectone.webp"],
      text: `Through the manage order dashboard, we wanted to show the status of each order with detailed information to offer better services to customers. `,
    },
    content2: {
        image: ["ship-delight-projectone-2.webp"],
        text: "This particular dashboard shows the order that is in the queue along with the customer details and action needed to fix the order issues (if any) before dispatching for smooth delivery.",
      },
  };

  const projectData2 = {
    title: `Distressed Report & <br />Live Status`,
    para: [
      `Through this dashboard, we wanted to show the data related to distressed orders and the real-time shipment status. We used cards to represent different reasons and colours to differentiate the live status of the order for better understanding.`,
    ],
    content: {
      image: ["ship-delight-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: "Exchange Status",
    para: [
      `We wanted to provide a detailed reason for every order category i.e. exchange, returns etc. to improve the service offering and also used a filter for the customisation option.`,
    ],
    content: {
      image: [
        "ship-delight-projectthree.webp",
        "ship-delight-projectthree-2.webp",
        "ship-delight-projectthree-3.webp",
        "ship-delight-projectthree-4.webp",
        "ship-delight-projectthree-5.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `Ship Delight is an e-commerce logistic fulfilment platform who wanted to upgrade their website for better targeting with professional help.`,
        `We assisted them by understanding their business goals and strategize on its positioning for improved website performance. `,
        `Our aim was to optimise the website for improved usability and functionality to stay ahead in the competitive landscape. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={24} />
      </div>
    </Layout>
  );
};

export default ShipDelight;

export const Head = () => (
  <Seo title="ShipDelight Website- Octet Design Studio" description="We provided our services such as UI UX Designing, WordPress Development, etc. to a logistic website that offers technological solutions to streamline processes." />
)